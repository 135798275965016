function ready(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
}

function generateElements(html) {
    const template = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content.children;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and
  }


const MOVEBY = .5;


class Bubble {
    playing = false;
    linkstate = 0;
    elWidth;
    elHeight;
    hDirection = 1;
    vDirection = 1;
    bubbleGrid = document.querySelector('[data-bubble-grid]');
    el;
    ww = window.innerWidth;
    wh = window.innerHeight;
    size = 1;
    minX = 0;
    minY = 0;
    maxX = 0;
    maxY = 0;
    x = 0;
    y = 0;

    constructor( props ) {
        this.props = props;
        this.size = props.acf.bubble_size ? (props.acf.bubble_size / 100) : 1;
        this.props.url = this.props.acf.direct_link ? this.props.acf.link.url : '#lightbox-' + this.props.slug;
        this.hDirection = Math.round(Math.random()) ? MOVEBY : MOVEBY * -1;
        this.vDirection = Math.round(Math.random()) ? MOVEBY : MOVEBY * -1;
        this.el = this.createDiv();
        this.bubbleGrid.appendChild( this.el );
        this.positionRandomly();
        this.addEvents();
        this.playing = true;
        this.run();
    }

    reset() {
        console.log('reset', this.el );
        var linkEl = this.el.querySelector('[data-bubble-link]');
        linkEl.classList.remove('hover');
        this.linkstate = 0;
    }

    createDiv() {
        const scale = this.size < .75 ? 0.8 : 0.6;
        let html = '<div class="bubble" style="transform:scale(' + this.size + ')"><a target="_blank" href="' + this.props.url + '" data-bubble-link><img src="' + this.props.acf.bubble_image + '"><img class="hover" style="--scale: ' + scale + '" src="' + this.props.acf.bubble_hover_image + '"></a></div>';

        return generateElements(html)[0];
    }

    addEvents() {
        const that = this;
        this.el.addEventListener('mouseover', function( e ) {
            that.playing = false;
        }, false );
        this.el.addEventListener('mouseout', function( e ) {
            var lightboxShow = document.querySelectorAll('.lightbox.show');
            if ( lightboxShow.length == 0 && that.linkstate == 0 ) {
                that.playing = true;
                that.run();
            }

        }, false );

        var linkEl = this.el.querySelector('[data-bubble-link]');
        if ( linkEl ) {

            if ( this.props.acf.direct_link ) {
                linkEl.addEventListener('click', function(e) {
                    clearTimeout( resetBubble );
                    if ( that.linkstate === 0 ) {
                        for( var i = 0; i < bubbles.length; i++ ) {
                            // bubbles[ i ].playing = false;
                            bubbles[ i ].reset();
                        }
                        e.preventDefault();
                        that.el.classList.add('hover');
                        that.linkstate = 1;
                        that.playing = false;
                        resetBubble = setTimeout(function(){
                            that.el.classList.remove('hover');
                            that.linkstate = 0;
                            that.playing = true;
                            that.run();
                        }, 10000);
                    } else if ( that.linkstate === 1) {
                        that.el.classList.remove('hover');
                        that.linkstate = 0;
                    }
                });

            } else {
                var resetBubble;
                linkEl.addEventListener('click', function(e) {
                    e.preventDefault();
                    clearTimeout( resetBubble );
                    if ( that.linkstate === 0 ) {
                        for( var i = 0; i < bubbles.length; i++ ) {
                            // bubbles[ i ].playing = false;
                            bubbles[ i ].reset();
                        }
                        that.el.classList.add('hover');
                        that.linkstate = 1;
                        that.playing = false;
                        resetBubble = setTimeout(function(){
                            that.el.classList.remove('hover');
                            that.linkstate = 0;
                            that.playing = true;
                            that.run();
                        }, 10000);
                    } else if ( that.linkstate === 1) {
                        var slug = that.props.slug;
                        var lightbox = document.querySelector('[data-lightbox-'+slug+']');
                        if ( lightbox ) {
                            lightbox.classList.add('show');

                            for( var i = 0; i < bubbles.length; i++ ) {
                                bubbles[ i ].playing = false;
                                bubbles[ i ].el.classList.add('hidden');
                            }

                            lightbox.addEventListener('scroll', function( e ){
                                console.log( 'scroll' );
                                if ( lightbox.scrollTop > 10 ) {
                                    lightbox.classList.add('scrolled');
                                } else {
                                    lightbox.classList.remove('scrolled');
                                }
                            });

                            var closeLink = lightbox.querySelector('[data-lightbox-close]');
                            if ( closeLink ) {
                                closeLink.addEventListener('click', function(e){
                                    e.preventDefault();
                                    lightbox.classList.remove('show');
                                    for( var i = 0; i < bubbles.length; i++ ) {
                                        bubbles[ i ].playing = true;
                                        bubbles[ i ].el.classList.remove('hidden');
                                        bubbles[ i ].run();
                                    }
                                });
                            }
                        }
                        that.el.classList.remove('hover');
                        that.linkstate = 0;
                    }

                });

            }
        }

        window.addEventListener('resize', function() {
            that.calculateMinMax();
        });
    }



    calculateMinMax() {
        this.ww = window.innerWidth;
        this.wh = window.innerHeight;
        this.el.style = '';
        this.elWidth = this.el.getBoundingClientRect().width * this.size;
        this.elHeight = this.el.getBoundingClientRect().height * this.size;
        this.minX = this.ww/2 * -1;
        this.minY = this.wh/2 * -1
        this.maxX = (this.ww/2) - this.elWidth;
        this.maxY = (this.wh/2) - this.elHeight;
    }

    positionRandomly() {
        this.calculateMinMax();
        if ( this.props.acf.start_x ) {
            this.x = this.props.acf.start_x / 100 * window.innerWidth;

            if ( this.x <  this.minX ) {
                this.x =  this.minX;
            }
            if ( this.x >  this.maxX ) {
                this.x =  this.maxX;
            }
            console.log(' this.x',  this.x );
        } else {
            this.x = getRandomInt( this.minX, this.maxX );
        }
        if ( this.props.acf.start_y ) {
            this.y = this.props.acf.start_y / 100 * window.innerHeight;
            if ( this.y < this.minY ) {
                this.y = this.minY;
            }
            if ( this.y > this.maxY ) {
                this.y = this.maxY;
            }
            console.log(' this.y',  this.y );
        } else {
            this.y = getRandomInt( this.minY, this.maxY );
        }
        this.el.style = 'width: ' + this.elWidth + 'px; height: ' + this.elHeight + 'px;transform: translateX(' + this.x + 'px) translateY(' + this.y + 'px);';
    }

    run( that = this ) {
        // console.log( 'this.vDirection', this.vDirection );
        if ( that.x + that.hDirection < that.maxX && that.x + that.hDirection > that.minX ) {
            that.x = that.x + that.hDirection;
        } else {
            that.hDirection = that.hDirection * -1;
            that.x = that.x + that.hDirection;
        }
        if ( that.y + that.vDirection < that.maxY && that.y + that.vDirection > that.minY ) {
            that.y = that.y + that.vDirection;
        } else {
            that.vDirection = that.vDirection * -1;
            that.y = that.y + that.vDirection;
        }

        that.el.style = 'width: ' + this.elWidth + 'px; height: ' + this.elHeight + 'px;transform: translateX(' + that.x + 'px) translateY(' + that.y + 'px);';
        if ( that.playing ) {
            // setTimeout( that.run, 25, that );
            // requestAnimationFrame(function() {
            //     that.run();
            // })
            setTimeout(function() {
                requestAnimationFrame(function() {
                    that.run();
                });
              }, 50, that );
        }
    }
}

let bubbles = [];


ready( async function() {

    // load bubbles

    const response = await fetch('/wp-json/wp/v2/bubbles');
    const data = await response.json();

    console.log( 'data', data );

    if ( data ) {
        for( let i = 0; i < data.length; i++ ) {
            const bubbleInfo = data[i];
            bubbles.push( new Bubble( bubbleInfo ) );

            // moveAround( newBubble );
        }

        document.querySelector('body').addEventListener('click', function(e) {
            // console.log( e );
            if ( e.target.tagName.toLowerCase() === 'canvas' ) {
                for( let i = 0; i < bubbles.length; i++ ) {
                    var bubble = bubbles[ i ];
                    bubble.reset();
                }
            }

        });

    } else {
        console.warn('no bubbles');
    }

    var openLightboxes = document.querySelectorAll('.lightbox.show');

    for( var i = 0; i < openLightboxes.length; i++ ) {
        var openLightbox = openLightboxes[i];
        setupLightbox(null, openLightbox, false, true );
    }

    function setupLightbox( toggleselector, lightboxselector, toggleEl = false, lightboxIsOpen = false ) {
        console.log( '----------------' );
        console.log('toggleselector', toggleselector);
        console.log('lightboxselector', lightboxselector);
        console.log('toggleEl', toggleEl);
        console.log('lightboxIsOpen', lightboxIsOpen);


        if ( lightboxIsOpen ) {
            var lightbox = lightboxselector;

        } else {
            var lightbox = document.querySelector(lightboxselector);
        }
        var toggle = toggleEl ? toggleEl : document.querySelector(toggleselector);

        if ( ( toggle && !lightboxIsOpen ) && lightbox ) {
            toggle.addEventListener('click', function(e) {
                e.preventDefault();
                var openLightboxes = document.querySelectorAll('.lightbox.show');
                if ( openLightboxes.length ) {
                    for( let i = 0; i < openLightboxes.length; i++ ) {
                        openLightboxes[i].classList.remove('show');
                    }
                }
                if ( openLightboxes.length === 0 ) {
                    lightbox.classList.add('show');
                }

                lightbox.addEventListener('scroll', function( e ){
                    // console.log( 'scroll' );
                    if ( lightbox.scrollTop > 10 ) {
                        lightbox.classList.add('scrolled');
                    } else {
                        lightbox.classList.remove('scrolled');
                    }
                });

                for( var i = 0; i < bubbles.length; i++ ) {
                    bubbles[ i ].playing = false;
                    bubbles[ i ].el.classList.add('hidden');
                }
                var closeLightbox = lightbox.querySelector('[data-lightbox-close]');
                if ( closeLightbox ) {
                    closeLightbox.addEventListener('click', function(e) {
                        e.preventDefault();
                        lightbox.classList.remove('show');
                        for( var i = 0; i < bubbles.length; i++ ) {
                            bubbles[ i ].playing = true;
                            bubbles[ i ].el.classList.remove('hidden');
                            bubbles[ i ].run();
                        }
                    })
                }
                var pageMenu = lightbox.querySelector('[data-page-menu]');
                var pageLinks = pageMenu.querySelectorAll('a');

                for( let i = 0; i < pageLinks.length; i++ ) {
                    var pageLink = pageLinks[i];
                    var slug = pageLink.getAttribute('href').replace('#','');
                    var newlightboxselector = '[data-lightbox-' + slug + ']';
                    var mewtoggleselector = '[data-toggle-' + slug + ']';
                    setupLightbox( mewtoggleselector, newlightboxselector, pageLink, false );
                }

                var overlayEl = lightbox.querySelector('[data-overlay-image]');
                var overlayToggleEl = lightbox.querySelectorAll('[data-toggle-overlay-image]');
                if ( overlayEl && overlayToggleEl ) {
                    for( var i = 0; i < overlayToggleEl.length; i++ ) {
                        overlayToggleEl[i].addEventListener('click', function(e) {
                            e.preventDefault();
                            var linkPostion = this.offsetTop;
                            console.log('linkPostion', linkPostion);
                            overlayEl.style = 'top: ' + linkPostion + 'px;';
                            overlayEl.classList.remove('hidden');
                            // let elementPosition = this.offsetTop - 16;
                            // console.log('elementPosition', elementPosition);
                            // const lightbox = document.querySelector('.lightbox.show');

                            // lightbox.scrollTo({
                            //     top: elementPosition,
                            //     behavior: "smooth"
                            // });
                        });
                    }

                    var closeOverlay = lightbox.querySelector('[data-overlay-close]');
                    closeOverlay.addEventListener('click', function(e) {
                        e.preventDefault();
                        overlayEl.classList.add('hidden');
                    });
                }

            });
        } else if ( lightboxIsOpen ) {
            lightbox.addEventListener('scroll', function( e ){
                // console.log( 'scroll' );
                if ( lightbox.scrollTop > 10 ) {
                    lightbox.classList.add('scrolled');
                } else {
                    lightbox.classList.remove('scrolled');
                }
            });

            for( var i = 0; i < bubbles.length; i++ ) {
                bubbles[ i ].playing = false;
                bubbles[ i ].el.classList.add('hidden');
            }
            var closeLightbox = lightbox.querySelector('[data-lightbox-close]');
            if ( closeLightbox ) {
                closeLightbox.addEventListener('click', function(e) {
                    e.preventDefault();
                    lightbox.classList.remove('show');
                    for( var i = 0; i < bubbles.length; i++ ) {
                        bubbles[ i ].playing = true;
                        bubbles[ i ].el.classList.remove('hidden');
                        bubbles[ i ].run();
                    }
                })
            }
            var pageMenu = lightbox.querySelector('[data-page-menu]');
            if ( pageMenu ) {
                var pageLinks = pageMenu.querySelectorAll('a');

                for( let i = 0; i < pageLinks.length; i++ ) {
                    var pageLink = pageLinks[i];
                    var slug = pageLink.getAttribute('href').replace('#','');
                    var newlightboxselector = '[data-lightbox-' + slug + ']';
                    var mewtoggleselector = '[data-toggle-' + slug + ']';
                    setupLightbox( mewtoggleselector, newlightboxselector, pageLink );
                }

            }

            var overlayEl = lightbox.querySelector('[data-overlay-image]');
            var overlayToggleEl = lightbox.querySelectorAll('[data-toggle-overlay-image]');
            if ( overlayEl && overlayToggleEl ) {
                for( var i = 0; i < overlayToggleEl.length; i++ ) {
                    overlayToggleEl[i].addEventListener('click', function(e) {
                        e.preventDefault();
                        var linkPostion = this.offsetTop;
                        console.log('linkPostion', linkPostion);
                        overlayEl.style = 'top: ' + linkPostion + 'px;';
                        overlayEl.classList.remove('hidden');
                        // let elementPosition = this.offsetTop - 16;
                        // console.log('elementPosition', elementPosition);
                        // const lightbox = document.querySelector('.lightbox.show');

                        // lightbox.scrollTo({
                        //     top: elementPosition,
                        //     behavior: "smooth"
                        // });
                    });
                }

                var closeOverlay = lightbox.querySelector('[data-overlay-close]');
                closeOverlay.addEventListener('click', function(e) {
                    e.preventDefault();
                    overlayEl.classList.add('hidden');
                });
            }
        }
    }

    setupLightbox('[data-open-infolightbox]', '[data-lightbox-infolightbox]', false);

    // load menu / infos
    // var toggleAboutLightbox = document.querySelector('[data-open-lightbox]');
    // var aboutLightbox = document.querySelector('[data-lightbox-form-f-net]');
    // if ( toggleAboutLightbox && aboutLightbox ) {
    //     toggleAboutLightbox.addEventListener('click', function(e) {
    //         e.preventDefault();
    //         aboutLightbox.classList.add('show');
    //         for( var i = 0; i < bubbles.length; i++ ) {
    //             bubbles[ i ].playing = false;
    //         }

    //         var pageMenu = aboutLightbox.querySelector('[data-page-menu]');
    //         var pageLinks = pageMenu.querySelectorAll('a');

    //         for( let i = 0; i < pageLinks.length; i++ ) {
    //             var pageLink = pageLinks[i];
    //             pageLink.addEventListener('click', function(e) {
    //                 e.preventDefault();
    //                 var targetLightbox = document.querySelector('[data-lightbox-' + this.getAttribute('href').replace('#','') + ']');
    //                 if ( targetLightbox ) {
    //                     targetLightbox.classList.add('show');
    //                 }
    //             });
    //         }


    //         var closeLightbox = aboutLightbox.querySelector('[data-lightbox-close]');
    //         if ( closeLightbox ) {
    //             aboutLightbox.addEventListener('click', function(e) {
    //                 e.preventDefault();
    //                 aboutLightbox.classList.remove('show');
    //                 for( var i = 0; i < bubbles.length; i++ ) {
    //                     bubbles[ i ].playing = true;
    //                     bubbles[ i ].run();
    //                 }
    //             })
    //         }
    //     })
    // }

    document.querySelectorAll('.lightbox a[href^="#"]').forEach(trigger => {
        trigger.onclick = function(e) {
            let hash = this.getAttribute('href');

            if ( hash !== '#' ) {
                e.preventDefault();

                let target = document.querySelector(hash);
                let headerOffset = 100;
                let elementPosition = target.offsetTop + 48;
                let offsetPosition = elementPosition - headerOffset;
                const lightbox = document.querySelector('.lightbox.show');

                lightbox.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }

        };
    });
});
